export const environment = {
    production: true,
    env: 'app',
    pageSize: 10,
    clientDashboardUserManualId: 333,
    clientDashboardFAQId: 334,
    clientCommitmentPageTemplateId: 8,
    pdfSkipFormId: "4715,4284,4242,1770",
    isCCT: true,
    apibaseurl: "https://cct-uat-webapp.azurewebsites.net/api/",    
    summaryReportUrl: 'https://app.powerbi.com/groups/b60b3d12-b8f8-496f-b3cd-cce5a043ff82/reports/6ea9a78e-fd80-4022-99da-5f10f5763c57?ctid=11873a1f-4c8d-450d-8dfb-e37a2e2557f8&experience=power-bi&subfolderId=11413',
    clientDashboardFAQs: 'https://cct-uat-bdbqejfpaefjbzh6.a01.azurefd.net/container-uat-cct/doc/client-dashboard-faqs-062320230615115225.pdf',
    cctUserManual: 'https://cct-uat-bdbqejfpaefjbzh6.a01.azurefd.net/container-uat-cct/doc/Client Commitment Tracker User Manual20230609164601.pdf',
    cctUserGuide: 'https://cct-uat-bdbqejfpaefjbzh6.a01.azurefd.net/container-uat-cct/doc/Client Commitment Tracker Internal User Guide.Sept 2024 update.pdf',    
    excludedDatasources: "ecrm",
    drmsPowerBIURL: 'https://app.powerbi.com/reportEmbed?reportId=691dbe76-f72b-42d9-b042-6de0f9964d52&autoAuth=true&ctid=11873a1f-4c8d-450d-8dfb-e37a2e2557f8&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtZC1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9',
    appInsights: {
        instrumentationKey: "8e5adc60-06ff-4d6b-a460-fac0db3caf00"
    },
    webApiConfig: {
        webApiEndpoint: 'https://cct-uat-webapp.azurewebsites.net/api/',
    },
    pingIdOAuthSettings: {
        authorizationUrl: 'https://fdc-fedsso-cat.firstdata.com/as/authorization.oauth2',
        tokenUri: 'https://fdc-fedsso-cat.firstdata.com/as/token.oauth2',
        redirectUri: 'https://cct-uat-webapp.azurewebsites.net',
        clientId: 'APM0009340CCTCAT',
        clientSecret: 'df3NF@dzHGv7$KMYNXkQE02z',  
        grantType: 'authorization_code',
        logoutUrl: 'https://fdc-fedsso.firstdata.com/idp/startSLO.ping'
    }
};
