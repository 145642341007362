<div class="heading-container fdl-2">
    <h1>Manual Commitment Request Form</h1>
    <button *ngIf="canEdit" type="button" class="btn fdl-btn btn-primary-orange" (click)="createNewCommitment();">
        <span class="material-icons">add</span>
        Create New Manual Commitment
    </button>
</div>

<div class="main-container-sub responsive-container col-settings-drawer-alignment">
    <div class="form-container">
        <div class="row">
            <section class="col-lg-12 p-0">
                <form class="form-border mb-3 box collapse-view" [formGroup]="filterForm" autocomplete="off">
                    <h2 class="h4 collapse-arrow" [ngClass]="[filterExists ? '':'collapsed']" data-toggle="collapse"
                        href="#collapseFilter">Search</h2>
                    <div class="filter-form-container filter-form-container-column2 search-filter collapse"
                        [ngClass]="[filterExists ? 'show':'']" id="collapseFilter">

                        <div class="filter-form-control pr-4 typeahead-wrapper">

                            <!-- <span
                                *ngIf="!filterForm.get('ClientTitle').valid && filterForm.get('ClientTitle').errors?.pattern && (filterForm.get('ClientTitle').touched || filterForm.get('ClientTitle').dirty  || isSubmitted)">
                                <i class="material-icons">warning</i>Please enter a valid Client Name
                            </span> -->
                            <fdl-type-ahead  class="filter-typeahead" label="Client Name:" formControlName="clientTitle"
                                placeholder="Type to search" [collection]="clientTitleSuggestions" [minLength]="0" [scrollable]="true">
                            </fdl-type-ahead>


                        </div>


                        <div class="filter-form-control pr-4 typeahead-wrapper">
                            <label>DUNS</label>
                            <!-- <span
                                *ngIf="!filterForm.get('DunsId').valid && filterForm.get('DunsId').errors?.pattern && (filterForm.get('DunsId').touched || filterForm.get('DunsId').dirty  || isSubmitted)">
                                <i class="material-icons">warning</i>Please enter a valid DUNS ID</span> -->
                            <fdl-type-ahead type="number" name="dunsId" placeholder="Type to search"
                                [collection]="dunsIdSuggestions" class="filter-typeahead" aria-invalid="false"
                                formControlName="dunsId" [minLength]="0" [scrollable]="true">
                            </fdl-type-ahead>

                        </div>

                        <div class="filter-form-control pr-4 typeahead-wrapper">

                            <!-- <span
                                *ngIf="!filterForm.get('ProductTitle').valid && filterForm.get('ProductTitle').errors?.pattern && (filterForm.get('ProductTitle').touched || filterForm.get('ProductTitle').dirty  || isSubmitted)">
                                <i class="material-icons">warning</i>Please enter a valid Product Name</span> -->
                            <fdl-type-ahead class="filter-typeahead" label="Product Name" formControlName="productTitle"
                                placeholder="Type to search" [collection]="productTitleSuggestions" [minLength]="0" [scrollable]="true"></fdl-type-ahead>
                        </div>
                        <div class="filter-form-control pr-4 typeahead-wrapper">

                            <!-- <span
                                *ngIf="!filterForm.get('CommitmentTitle').valid && filterForm.get('CommitmentTitle').errors?.pattern && (filterForm.get('CommitmentTitle').touched || filterForm.get('CommitmentTitle').dirty  || isSubmitted)">
                                <i class="material-icons">warning</i>Please enter a valid Commitment Title
                            </span> -->
                            <fdl-type-ahead class="filter-typeahead" label="Commitment Title"
                                formControlName="commitmentTitle" placeholder="Type to search"
                                [collection]="commitmentTitleSuggestions" [minLength]="0" [scrollable]="true"></fdl-type-ahead>

                        </div>

                        <!--<div class="filter-form-control pr-4 typeahead-wrapper">
                       
                        <span *ngIf="!filterForm.get('CommitmentStatus').valid && filterForm.get('CommitmentStatus').errors?.pattern && (filterForm.get('CommitmentStatus').touched || filterForm.get('CommitmentStatus').dirty  || isSubmitted)">
                            <i class="material-icons">warning</i>Please enter a valid Commitment Status
                        </span>
                            <fdl-type-ahead class="filter-typeahead" label="Commitment Status" formControlName="CommitmentStatus" placeholder="Type to search" [collection]="clienttitlesuggestions"></fdl-type-ahead>
                            
                      </div> -->

                        <div class="buttom-wrapper">
                            <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search"
                                (click)="filterList()">Search</button>
                        </div>
                        <div class="ml-2 buttom-wrapper" *ngIf="filtered">
                            <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Reset"
                                (click)="clearFilterList()">Reset</button>
                        </div>
                    </div>
                </form>
                <!-- Note: Use the data-parent attribute to make sure that all collapsible elements under the specified
                parent will be closed when one of the collapsible item is shown. -->
                <div id="accordion">
                    <div class="data-table-container">
                        <div class="table-responsive form-responces s-overflow-auto">
                            <div class="justify-content-between d-flex">
                                <div class="headline-container mb-1">
                                    <p *ngIf="pagerObject">Total {{pagerObject.totalCount | number}} items</p>
                                </div>
                                <div class="d-flex">
                                    <div class="action-container fdl-2">
                                        <button (click)="openSaveDrawer()" [disabled]="!enableSaveButton" class="btn fdl-btn btn-primary-orange mr-2"
                                            id="actionUpdateCommitmentsDwDialog" type="button">
                                            Save
                                        </button>
                                    </div>
                                    <div class="refresh-icon-link mt-1">
                                        <a id="refreshTask" title="Refresh List" class="">
                                            <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th role="columnheader" [attr.colspan]="column.colspan" scope="col"
                                            *ngFor="let column of displayColumns; let i = index;">
                                            <span>
                                                <a (click)="sort(column.key, 'columnSettings')" href="javascript:void(0);">
                                                    {{column.text}}
                                                    <i class="material-icons msort14" aria-hidden="true" *ngIf="isAsc && filterColumn === column.key">arrow_upward</i>
                                                    <i class="material-icons msort14" aria-hidden="true" *ngIf="!isAsc && filterColumn === column.key">arrow_downward</i>
                                                </a>
                                            </span>
                                        </th>

                                        <th style="background: white; position: sticky; right: 0; text-align: right;" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let item of commitments; let i = index">
                                    <tr>
                                        <td>
                                            <div class="mt-3">
                                                <a id="{{'arrow'}}" href="#collapseOne{{i}}"
                                                    class="material-icons td-parent-accordion collapsed"
                                                    data-toggle="collapse" aria-expanded="false"></a>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="">
                                                <label class="label-checkbox" for="{{'dataCheckbox'+ i}}"></label>
                                                <input [disabled]="!canEdit" [(ngModel)]="item.isTracked" (change)="onTrackChanged(item)" id="{{'dataCheckbox'+ i}}" type="checkbox" style="cursor: pointer;">
                                            </div>
                                           
                                        </td>
                                        <td>
                                            <div class="mt-3">{{item.id}}</div>
                                        </td>
                                        <td colspan="3">
                                            <div class="product-content mt-3">{{getClientName(item)}}</div>
                                        </td>
                                        <td colspan="2">
                                            <div class="product-content mt-3">{{getClientDuns(item) }}</div>
                                        </td>
                                        <td colspan="2">
                                            <div class="product-content mt-3">{{item.title}}</div>
                                        </td>

                                        <td colspan="2">
                                            <div class="product-content mt-3">{{item.requestorName}}</div>
                                        </td>
                                        <td colspan="2">
                                            <div class="product-content mt-3">{{item.requestorEmail}}</div>
                                        </td>
                                        <td colspan="1">
                                            <div class="product-content mt-3">{{item.createdOn | date: dateFormat}}</div>
                                        </td>
                                        <td colspan="1">
                                            <div class="product-content mt-3">{{item.modifiedOn | date: dateFormat}}</div>
                                        </td>
                                        <td (click)="addZIndex(i)" [attr.id]="'actions-' + i" class="actions"
                                            role="cell">
                                            <div (click)="addZIndex(i)" class="kebab-menu dropleft">
                                                <a href="javascript:void(0);" (click)="addZIndex(i)"
                                                    data-toggle="dropdown" id="headerSwitcher" aria-expanded="false">
                                                    <span ariaReadOnly="true" class="material-icons"> more_vert </span>
                                                </a>
                                                <div x-placement="left-start"
                                                    class="dropdown-menu dropdown-menu-dark top-15 header-dropdown-switcher">
                                                    <div class="dropdown-list-option">
                                                        <a class="dropdown-item" (click)="onView(item.id)">View </a>
                                                        <div *ngIf="item.isEditable">
                                                            <a  class="dropdown-item" (click)="onViewEdit(item.id)">View &amp; Edit</a>
                                                            <a  class="dropdown-item" (click)="onDelete(item)" data-toggle="modal" data-target="#deleteModal_one">Delete</a>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border-top: none;" colspan="18">
                                            <div id="collapseOne{{i}}" class="collapse">
                                                <div class="">
                                                    <div class="data-table-container product-bg">
                                                        <div class="table-responsive form-responces s-overflow-auto">
                                                            <table class="table table-hover product-bg">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <div>Product Name</div>
                                                                        </th>
                                                                        <th>
                                                                            <div>eCRM Opportunity ID </div>
                                                                        </th>
                                                                        <th>
                                                                            <div>eCRM Opportunity TCV</div>
                                                                        </th>
                                                                        <th>
                                                                            <div>eCRM Opportunity ACV</div>
                                                                        </th>
                                                                        <th colspan="5">
                                                                            <div>BU(s)</div>
                                                                        </th>
                                                                        <th>
                                                                            <div>Platform</div>
                                                                        </th>
                                                                        <th>
                                                                            <div>Commitment Type</div>
                                                                        </th>
                                                                        <th>
                                                                            <div>FAP</div>
                                                                        </th>
                                                                        <th>
                                                                            <div>Obligation Type</div>
                                                                        </th>
                                                                        <th>
                                                                            <div>Status</div>
                                                                        </th>
                                                                        <th>
                                                                            <div>Client Requested Commitment Date</div>
                                                                        </th>
                                                                        <th>
                                                                            <div>Executive Leader Name</div>
                                                                        </th>
                                                                        <th>
                                                                            <div>Executive Leader Email</div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr
                                                                        *ngFor="let product of item.products; let colIndex = index">
                                                                        <td>
                                                                            <div class="product-content">
                                                                                {{product.productName}}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div class="product-content">
                                                                                {{product.ecrmOpportunityId}}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div class="product-content">
                                                                                {{product.ecrmOpportunityTCV}}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div class="product-content">
                                                                                {{product.ecrmOpportunityACV}}</div>
                                                                        </td>
                                                                        <td colspan="5">
                                                                            <div class="product-content">
                                                                                {{product.businessUnit?.title}}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div class="product-content">
                                                                                {{product.platformNames}}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div class="product-content">
                                                                                {{product.commitmentType?.title}}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div class="product-content">
                                                                                {{product.fap?.title}}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div class="product-content">
                                                                                {{product.obligationType?.title}}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div class="product-content">
                                                                                {{product.status?.status}}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div class="product-content">
                                                                                {{product.clientRequestedDate | dateFormat}}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div class="product-content">
                                                                                {{product.execLeaderName}}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div class="product-content">
                                                                                {{product.execLeaderEmail}}</div>
                                                                        </td>

                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="pagerObject">
                            <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 "
                                [pagerObject]="pagerObject" (pagingChanged)="changePage($event)"></app-pagination>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

<!-- Start of delete drawer-->
<div *ngIf="currentItemDeleted && isShowConfirmDeleteDrawer">
    <div id="" class="map-dw">
        <div tabindex="-1" aria-labelledby="addContacts" aria-expanded="true" role="region"
            class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open">
            <div class="drawer-contents commitment-dw  col-xs-12 col-sm-12 col-md-12 " style="height: calc(100vh + 140px);">
                <div class="dw-header">
                    <div class="modal-header-container">
                        <button (click)="closeDeleteDrawer()" type="button" data-dismiss="modal" tabindex="0" autofocus=""
                            aria-label="Close popup" class="arrow-dw"><span class="material-icons">
                                arrow_back
                            </span></button>
                        <h4 class="modal-title">
                            Delete Commitment
                            <p>The commitment will be deleted. Please review and submit.</p>
                        </h4>
                    </div>
                </div>
                <div class="dw-body pr-0">
                    <div class="form-container">
                        <div class="form-row">
                            <div class="col-md-12  data-company-table">
                                <table aria-describedby="tblDesc" tabindex="0" class="table table-hover table-label">
                                    <thead>
                                        <tr role="row">
                                            <th role="columnheader" tabindex="0" scope="col">ID</th>
                                            <th role="columnheader" scope="col"><a href="#" tabindex="0"> Title </a></th>
                                            <th role="columnheader" tabindex="0" scope="col">Client</th>
                                            <th role="columnheader" tabindex="0" scope="col">Date/Time</th>
                                            <th role="columnheader" tabindex="0" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr role="row" class="alert-message">
                                            <td role="cell" tabindex="0">{{currentItemDeleted.id}}</td>
                                            <td role="cell" tabindex="0">{{currentItemDeleted.title}}</td>
                                            <td role="cell" tabindex="0">{{currentItemDeleted.ecrmClientName}}</td>
                                            <td role="cell" tabindex="0">{{currentItemDeleted.createdOn| date: 'MM/dd/yyyy HH:mm'}}</td>
                                            <td role="cell" tabindex="0"><i class="material-icons" aria-hidden="true">warning</i> Deleted</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="fdl-2 button-container">
                            <button type="button" data-dismiss="modal" class="btn fdl-btn btn-secondary-light dCancel" (click)="closeDeleteDrawer()" id="dCancel">
                                Cancel
                            </button>
                            <div>
                                <button type="button" class="btn fdl-btn btn-primary-orange ml-2" (click)="deleteCommitment()">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="backdrop-space">
        <div class="backdrop" style="height: calc(100vh + 200px);"></div>
    </div>
</div>
<!-- end of delete drawer-->

<!-- Start of save drawer-->
<div *ngIf="this.commimentsTobeUpdated && this.commimentsTobeUpdated.length > 0 && isShowConfirmSaveDrawer">
    <div id="" class="map-dw">
        <div tabindex="-1" aria-labelledby="addContacts" aria-expanded="true" role="region"
            class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open">
            <div class="drawer-contents commitment-dw  col-xs-12 col-sm-12 col-md-12 " style="height: calc(100vh + 140px);">
                <div class="dw-header">
                    <div class="modal-header-container">
                        <button (click)="closeSaveDrawer()" type="button" data-dismiss="modal" tabindex="0" autofocus=""
                            aria-label="Close popup" class="arrow-dw"><span class="material-icons">
                                arrow_back
                            </span></button>
                        <h4 class="modal-title  ">
                            Update Commitment
                            <p>The list below displays updated commitment(s). Please review and submit.</p>
                        </h4>
                    </div>
                </div>
                <div class="dw-body pr-0">
                    <div class="form-container">
                        <div class="form-row">
                            <div class="col-md-12  data-company-table">
                                <table aria-describedby="tblDesc" tabindex="0" class="table table-hover table-label">
                                    <thead>
                                        <tr role="row">
                                            <th role="columnheader" tabindex="0" scope="col">ID</th>
                                            <th role="columnheader" scope="col"><a href="#" tabindex="0"> Title </a>
                                            </th>
                                            <th role="columnheader" tabindex="0" scope="col">Date/Time</th>
                                            <th role="columnheader" tabindex="0" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr role="row" *ngFor="let commitment of commimentsTobeUpdated" [ngClass]="getStatusCSSClass(commitment)" class="">
                                            <td role="cell" tabindex="0">{{commitment.id}}</td>
                                            <td role="cell" tabindex="0">{{commitment.title}}</td>
                                            <td role="cell" tabindex="0">{{commitment.createdOn| date: 'MM/dd/yyyy HH:mm'}}</td>
                                            <td role="cell" tabindex="0" *ngIf="commitment.isTracked"><i class="material-icons" aria-hidden="true">check_circle</i> Updated</td>
                                            <td role="cell" tabindex="0" *ngIf="!commitment.isTracked"><i class="material-icons" aria-hidden="true">warning</i> Untracked</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="fdl-2 button-container">
                            <button type="button" data-dismiss="modal" class="btn fdl-btn btn-secondary-light dCancel" (click)="closeSaveDrawer()" id="dCancel">
                                Cancel
                            </button>
                            <div>
                                <button type="button" class="btn fdl-btn btn-primary-orange ml-2" (click)="submitChanges()">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="backdrop-space">
        <div class="backdrop" style="height: calc(100vh + 200px);"></div>
    </div>
</div>
<!-- end of save drawer-->
<div [ngStyle]="{'background-color': hasError ? '#b02e2b' : '#059862' }" id="notify">
    <div *ngIf="hasError">
        <span><i class="material-icons" aria-hidden="true">warning</i> Some thing went wrong. Please contact system administrator</span>
    </div>
    <div *ngIf="!hasError">
        <span><i class="material-icons" aria-hidden="true">check_circle</i> {{successMsg}}</span>
    </div>
</div>