import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FilterPipe } from '../../Pipes/filter.pipe';
import { PermissionsService } from '../../../services/permissions.service';
import * as $ from 'jquery';
import { UserService } from 'src/app/services/user.service';
import { PreferenceService } from '../../../services/preference.service';
import { ContentService } from 'src/app/services/content.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PERMISSIONS } from 'src/app/core/constants';
import { forEachChild } from 'typescript';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ["./data-table.component.css"],
  providers: [FilterPipe]
})
export class DataTableComponent implements OnInit, OnChanges {

  @Input() columnChecker: (param: { name: string, display: string }) => boolean;

  @Input() rows;
  @Input() keys: string[];
  @Input() displayColumns: string[];
  @Input() already;
  @Input() hidePartialLaunchButton: boolean = false;
  @Input() showAttachment: boolean = false;
  @Input() showMenu: boolean = true;
  @Input() enableSorting: boolean = true;
  @Input() showCheckbox: boolean = true;
  @Input() customFormFieldListJson: any;
  @Input() searchString;
  @Input() category;
  @Input() id;
  @Input() tableId = "one";
  @Input() formName;
  @Input() package;
  @Input() searchStringFieldName = "title";
  @Input() clientTitle;
  @Input() formSets;
  @Input() showView: boolean = true;
  @Input() showViewEdit: boolean = false;
  @Input() drawers: any[];
  @Input() showDelete: boolean = false;
  @Input() showUpdate: boolean = false;
  @Input() showEdit: boolean = false;
  @Input() showDelegate: boolean = false;
  @Input() showChangeApprover: boolean = false;
  @Input() type: string = '';
  @Input() showAssetOptions: boolean = false;
  @Input() showAttachmentOptions: boolean = false;
  @Input() showViewHistory: boolean = false;
  @Input() showViewCompare: boolean = false;
  @Input() showViewTimeline: boolean = false;
  @Input() showEditFormDetails: boolean = false;
  @Input() downloadFormFields: boolean = false;
  @Input() showViewProjectTimeline: boolean = false;
  @Input() showExtractionTemplate: boolean = false;
  @Input() showDownload: boolean = false;
  @Input() showRequestExtract: boolean = false;
  @Input() showRevert: boolean = false;
  @Input() isHistory: boolean = false;
  @Input() listname: any = '';
  @Input() showReinitiate: boolean = false;
  @Input() isClient: boolean = false;
  @Input() showLaunchButton: boolean = false;
  @Input() hideAssignLaunch: boolean = false;
  @Input() showMyAssignedActionButton: boolean = false;
  @Input() showViewButton: boolean = false;
  @Input() showOrganizationViewButton: boolean = false;
  @Input() showActionButton: boolean = false;
  @Input() actionName: string = '';
  @Input() showTemplateDelete: boolean = false;
  @Input() showRadio: boolean = false;
  @Input() hideAllCheck: boolean = false;
  @Input() selectedRadioValue;
  @Input() customClasses: string = '';
  @Input() status;
  @Input() respondentName;
  @Input() showTemplateSend: boolean = false;
  @Input() emailSets;
  @Input() isDragable: Boolean = false;
  @Input() showCopy: boolean = false;
  @Input() showMoveToPhase: boolean = false;
  @Input() showMyDeleteActionButton: boolean = false;
  @Output() emailTemplatesCount = new EventEmitter();
  @Input() editableRows: boolean = false;
  @Output() rowsChanged = new EventEmitter<{ data: any }>();

  formFieldChangeCounter: number = 0;
  link: any = '';
  isEdit: boolean = false;
  isArticle: boolean = false;
  isCategory: boolean = false;
  @Input() showDelink: boolean = false;
  isAsc: boolean
  filterColumn: string
  allSelected: boolean = false;
  showClone: boolean = false;
  isUserList: boolean = false;
  isEmailTemplateList: boolean = false;
  isRoleList: boolean = false;
  isClientList: boolean = false;
  totalRows: number;
  activeLi: number = 100000;
  @Input() counter: string;
  @Input() formFetchAction: boolean = false;
  @Input() canDeleteEntity: (args: any) => boolean;
  @Input() canUpdateProjectStatus: (args: any, statusLabel: string) => boolean;
  @Output() buttonClicked = new EventEmitter<{ action: string, data: string }>();
  @Output() removeClicked = new EventEmitter<{ action: string, data: any }>();
  @Output() connectedFormClicked = new EventEmitter<{ action: string, data: string, pageId: string, stepId: string }>();
  @Output() deleteBtnClicked = new EventEmitter<{ action: string, data: string, formId: string, title: string }>();
  @Output() sortingClicked = new EventEmitter<{ key: string, isAsc: boolean }>();
  @Output() launchBtnClicked = new EventEmitter<{ action: string, data: string }>();
  @Output() actionBtnClicked = new EventEmitter<{ action: string, data: any }>();
  @Output() favButtonClicked = new EventEmitter<{ action: string, data: string }>();
  @Output() attachmentClicked = new EventEmitter<{ action: string, data: string }>();
  @Output() callbackpreference = new EventEmitter<{ data: any }>();
  @Output() responsesCount = new EventEmitter();
  @Output() onDroppedOut = new EventEmitter();
  @Output() onDroppedIn = new EventEmitter();
  @Output() deleteIntakeClicked = new EventEmitter<any>();
  @Input() showFavoriteIcon: boolean = false;
  @Input() addViewProjectClass: boolean = false;
  targetDeletableRow: any = {};
  @Input() ignoreSortingColumns = ['description', 'formsCount', 'fileSize', 'count', 'formType', 'level', 'entityName', 'status', 'entityLevel'];
  @Input() staticColumns: boolean = false;
  @Input() public checkDeleteForEachRow = false;
  @Input() isResetSortingIndicator: boolean = false;
  statusClass: string
  userId: any;
  userOrgId: number;
  isAdmin: boolean = false;
  isCreateProjectAllowed: boolean = false;
  content: any = [];
  index = 0;

  constructor(private filterPipe: FilterPipe, private permissionService: PermissionsService, private userService: UserService, private contentService: ContentService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.initCheckAllValue();
    if (changes.isResetSortingIndicator?.currentValue) {
      this.resetSortingIndicator();
    }
  }

  allowDelete(row): boolean {
    if (this.canDeleteEntity) {
      return this.canDeleteEntity(row);
    }

    return false;
  }

  isRespondentRoleOnly() {
    let roles = this.permissionService.userData?.roles
    let isRespondent: boolean = false;
    let isIntake: boolean = false;
    for (let i in roles) {
      let role = roles[i].roleTitle.toLowerCase();
      if (role.indexOf("respondent") == -1)
        isRespondent = true;
      if (role.indexOf("intake") == -1)
        isIntake = true
    }

    return isRespondent && isIntake;
  }

  showKebab(id: any): void {
    this.activeLi = id;
  }

  onClick(id, action) {
    this.buttonClicked.emit({ action: action, data: id });
  }
  checkHide(drawer, row) {
    return drawer.checkHide && drawer.checkHide(row);
  }
  connectedFormClick(row, action, pageKey: any = '', step = '') {
    if (pageKey == '')
      this.removeClicked.emit({ action: action, data: row })
    else
      this.connectedFormClicked.emit({ action: action, data: row, "pageId": pageKey, "stepId": step })
  }

  refereshColums(data: any) {
    if (!this.staticColumns) {
      this.displayColumns = data.data.displayColumns;
      this.keys = data.data.keys;
      if (this.callbackpreference) {
        this.callbackpreference.emit({ 'data': data.data })
      }
    }
  }

  onClick_launch(row, action) {
    if (action == 'Initiate') {
      $('#containerPage').scrollTop(0);
      $('.container-page').addClass('overflow-hidden');
    }
    if (action == 'User') {
      $('#containerPage').scrollTop(0);
      $('.container-page').addClass('overflow-hidden');
    }
    this.launchBtnClicked.emit({ action: action, data: row });
  }

  onClick_Action(row, action) {
    this.actionBtnClicked.emit({ action: action, data: row });
  }

  onClick_delete() {
    this.deleteBtnClicked.emit({ action: 'delete', data: this.targetDeletableRow['id'], formId: this.targetDeletableRow['formId'], title: this.targetDeletableRow['title'] || this.targetDeletableRow['name'] || this.targetDeletableRow['emailTitle'] })
  }

  ngOnInit() {
    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }
    this.userId = this.userService.getUserListData().id;
    this.userOrgId = this.userService.getUserListData().organization;
    this.isAdmin = this.userService.getUserListData().isAdmin;
    this.isEdit = this.userService.getPermissions()["showhelpArticlelink"];
    this.isCreateProjectAllowed = this.permissionService.isCreateProjectAllowed();
    this.totalRows = (this.rows?.length) ?? 0;
    const user = this.userService.getUserListData();
    this.showRequestExtract = user.type !== 'Client';
    // this.rows.forEach(row => {
    //   row.selected = false;
    // });
    if (this.type == 'Response' || this.type == 'Responses') {
      this.link = '/response/view/responses/';
    } else if (this.type == 'Form')
      this.link = '/admin/form/view/';
    else if (this.type == 'Project') {
      this.link = '/projects/view/';
    }
    else if (this.type == 'Resource Article' || this.type == 'Resource Category') {
      this.isArticle = true;
    }
    else if (this.type == 'User') {
      this.isUserList = true;
      this.showClone = true;
    }
    else if (this.type == 'Email Template') {
      this.isEmailTemplateList = true;
    }
    else if (this.type == 'Role') {
      this.isRoleList = true;
      this.showClone = true;
    } else if (this.type == 'Client' && !this.isAdmin && !user.permissions.includes(PERMISSIONS.Clients_ReadWrite_All) && user.permissions.includes(PERMISSIONS.Clients_Create_Only)) {
      this.isClientList = true;
    }
    else {
      this.link = '';
    }

    if (!this.formSets) {
      this.formSets = [{ 'assignedFormsList': [] }];
    }
    this.initCheckAllValue();
  }

  rowsSelected() {
    let rowsCount: number = 0;
    let tempRowArray = [];

    for (let i = 0; i < this.rows.length; i++) {
      this.rows[i].showLaunchButton = false;
      tempRowArray.push(this.rows[i]);
      if (this.rows[i].selected === true) {
        rowsCount = rowsCount + 1;
      }
    }
    for (let k in tempRowArray) {
      for (let kk in this.formSets[this.counter].assignedFormsList) {
        let row = this.formSets[this.counter].assignedFormsList[kk]
        if (row.id == tempRowArray[k].id) {
          this.formSets[this.counter].assignedFormsList.splice(kk, 1)
        }
      }
    }
    for (let k in tempRowArray) {
      if (tempRowArray[k].selected === true)
        this.formSets[this.counter].assignedFormsList.push(tempRowArray[k]);
    }

    if (rowsCount === this.rows.length) {
      this.allSelected = true;
    }

    this.responsesCount.emit(rowsCount);
  }

  allFormsSelected() {
    let filteredSelectedRows;
    let allRows = Object.assign([], this.rows);
    filteredSelectedRows = this.filterPipe.transform(allRows, 'title', this.searchString);
    if (this.respondentName) {
      filteredSelectedRows = this.filterPipe.transform(filteredSelectedRows, 'assignedToName', this.respondentName);
    }

    this.rows.forEach(row => {
      row.selected = false;
      filteredSelectedRows.forEach(selectedRow => {
        if (selectedRow.id === row.id) {
          row.selected = this.allSelected;
          this.rowsSelected();
        }
      })
    });
  }

  formSelected() {
    this.allSelected = this.rows.every(function (row) {
      return row.selected == true;
    });
    this.rowsSelected();
  }

  extractionTypeSelected() {
    return true;
  }

  formFieldChanged(row, parentField: string = '', childField: string = '') {
    const formFieldKey = `${parentField}OriginalValue`;
    if (row.customeFormFieldListJsonAnswer[formFieldKey] !== row.customeFormFieldListJsonAnswer[parentField])
      this.formFieldChangeCounter = this.formFieldChangeCounter + 1;
    else
      this.formFieldChangeCounter = this.formFieldChangeCounter - 1;

    if (parentField !== '' && childField !== '') {
      const childFormFieldKey = `${childField}OriginalValue`;
      if (row.customeFormFieldListJsonAnswer[childFormFieldKey] !== row.customeFormFieldListJsonAnswer[childField])
        this.formFieldChangeCounter = this.formFieldChangeCounter + 1;
    }

    if (this.formFieldChangeCounter > 0)
      row.selected = true;
    else
      row.selected = false;

    this.formSelected();
  }

  updateDependentField(row, parentField: string = '', childField: string = '') {
    this.formFieldChanged(row, parentField, childField);

    if (parentField !== '' && childField !== '') {
      if (row.customeFormFieldListJsonAnswer[parentField] === false || row.customeFormFieldListJsonAnswer[parentField] === 'false' || row.customeFormFieldListJsonAnswer[parentField] === undefined) {
        row.customeFormFieldListJsonAnswer[childField] = "";
      }
    }
  }

  sort(key) {
    if (this.filterColumn !== key) {
      this.filterColumn = key;
      this.isAsc = true;
    }
    else {
      this.isAsc = !this.isAsc;
    }
    this.sortingClicked.emit({ key: this.filterColumn, isAsc: this.isAsc });
  }

  applySorting(key): boolean {
    return this.enableSorting && !(this.ignoreSortingColumns.includes(key)) && (this.rows.length > 0);
  }

  resetSortingIndicator(): any {
    this.filterColumn = null;
  }

  isPhone(key) {
    return key.toLowerCase().indexOf('phone') > -1;
  }
  isText(key) {
    return key.toLowerCase().indexOf('inputtext') > -1;
  }
  isPage(key) {
    return key.toLowerCase().indexOf('pageinputtext') > -1;
  }
  isStep(key) {
    return key.toLowerCase().indexOf('stepinputtext') > -1;
  }
  isDate(key) {
    return (key.indexOf('modifiedOn') > -1 || key.indexOf('startDate') > -1 || key.indexOf('modifiedON') > -1 || key.indexOf('createdOn') > -1 || key.indexOf('createdON') > -1 || key.indexOf('startTime') > -1 || key.indexOf('endTime') > -1);
  }
  isDateOnly(key) {
    return key.indexOf('dueDate') > -1 || key.indexOf('modifiedDate') > -1;
  }

  isStatus(key) {
    return (key === 'responseStatusTitle' || key === 'statusTitle' || key === 'status' || key === 'pageTemplateResponseStatusTitle');
    //  return (key.indexOf('responseStatusTitle') > -1 || key.indexOf('statusTitle') > -1 || key.indexOf('status') > -1 || key.indexOf('pageTemplateResponseStatusTitle') > -1);
  }

  isFlag(key) {
    return (key === 'isRisk');
  }

  isNotificationState(key) {
    return (key === 'state');
  }

  isImageIcon(key) {
    return (key === 'imageIcon');
  }

  isGroup(key) {
    return (key === 'isGroup');
  }

  getFlagClasses(row: any, key: string) {
    const value = row[key];
    console.log('commitStatusFromgetFlagClasses: ' + row['commitmentStatus'])
    if (key == 'isRisk') {
      if ((row['commitmentStatus'] == 'In Progress' && value === 'true') ||
          (row['commitmentStatus'] == 'Not Started' && value === 'true') ||          
          (row['commitmentStatus'] === 'Fiserv - On Hold' && value === 'true')) {
        return 'red';
      }
      else if (row['commitmentStatus'] == 'In Progress' && value === 'false') {
        return 'green';
      }

      return "blank";
    }

    return "";
  }

  getStatusCSSClass(val: any) {
    if (val == 'Overdue' || val == 'Failed') {
      this.statusClass = 'badge-table-status red';
    }
    else if (val == 'On Hold' || val == 'Delayed') {
      this.statusClass = 'badge-table-status hold';
    }
    else if (val == 'Cancel') {
      this.statusClass = 'badge-table-status gray';
    }
    else if (val == 'Rejected') {
      this.statusClass = 'badge-table-status rejected';
    }
    else if (val == 'Completed' || val == 'Success') {
      this.statusClass = 'badge-table-status green';
    }
    else if (val == 'Under Review' || val == 'Testing') {
      this.statusClass = 'badge-table-status keppel';
    }
    else if (val == 'In Progress' || val == 'InProgress') {
      this.statusClass = 'badge-table-status yellow';
    }
    else if (val == 'Not Started' || val == 'NotStarted' || val == 'Active') {
      this.statusClass = 'badge-table-status blue';
    }

    return this.statusClass;
  }

  getUserNotificationStateCSSClass(val) {
    if (val.toLowerCase() == 'new' || val.toLowerCase() == 'unread') {
      this.statusClass = 'badge-table-status green';
    }
    else {
      this.statusClass = 'badge-table-status gray';
    }

    return this.statusClass;
  }

  deleteBtnClickHandler(row: any): void {
    this.targetDeletableRow = row;
  }

  onFavoriteClick(id, action) {
    this.favButtonClicked.emit({ action: action, data: id });
  }

  onAttachmentClick(attachment, action) {
    this.attachmentClicked.emit({ action: action, data: attachment })
  }

  showLaunchButtons(i) {
    if (this.formSets[this.counter].assignedFormsList.length == 0)
      return true;

    return this.rows[i].showLaunchButton;
  }

  allEmailsSelected() {
    let allRows = Object.assign([], this.rows);

    this.rows.forEach(row => {
      row.selected = false;
      allRows.forEach(selectedRow => {
        if (selectedRow.id === row.id) {
          row.selected = this.allSelected;
          this.rowsSelectedEmailTemplates();
        }
      })
    });
  }

  rowsSelectedEmailTemplates() {
    let rowsCount: number = 0;
    let tempRowArray = [];

    for (let i = 0; i < this.rows.length; i++) {
      if (this.rows[i].selected === true) {
        tempRowArray.push(this.rows[i]);
        rowsCount = rowsCount + 1;
      }
    }
    this.emailSets[0].selectedEmailTemplateList = JSON.parse(JSON.stringify(tempRowArray));
    this.emailTemplatesCount.emit(rowsCount);
  }

  emailsSelected() {
    this.allSelected = this.rows.every(function (row) {
      return row.selected == true;
    })
    this.rowsSelectedEmailTemplates();
  }

  shouldShowColum(name: string | null, display: string | null) {
    if (this.columnChecker) {
      const obj = {
        name: name || "",
        display: display || ""
      };
      return this.columnChecker.call(this, obj) as boolean;
    }
    return true;
  }

  private initCheckAllValue(): void {
    if (this.showCheckbox && this.rows) {
      const checkedItems = this.rows.filter(item => item.selected);
      this.allSelected = checkedItems.length === this.rows.length && this.rows.length > 0;
    }
  }

  toggleFolder(row) {
    row.isCollapsed = !row.isCollapsed;
    this.rows.forEach((element) => {
      if (element.folderId == row.id) {
        element.isCollapsed = !element.isCollapsed;
      }
    });
  }

  /* Drag & drop */
  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex === event.currentIndex) {
      return;
    }

    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    const rowsData = event.container.data;
    const prevItem = rowsData[event.currentIndex - 1];
    const nextItem = rowsData[event.currentIndex + 1];
    if (
      (!prevItem && nextItem['isGroup']) ||
      (nextItem && !prevItem['isGroup']) ||
      (prevItem && nextItem && prevItem['isSub'] && nextItem['isGroup'])
    ) {
      event.item.data.isSub = false;
      event.item.data.folderId = null;
      this.onDroppedOut.emit(event.item.data);
    } else {
      event.item.data.isSub = true;
      let folderId = null;
      if (prevItem && prevItem['isGroup']) {
        folderId = prevItem['id'];
      } else if (nextItem && nextItem['isGroup']) {
        folderId = nextItem['id'];
      }
      event.item.data.folderId = folderId;
      this.onDroppedIn.emit(event.item.data);
    }
  }

  allowUpdateProjectStatus(row: any, statusLabel: string): boolean {
    if (this.canUpdateProjectStatus) {
      return this.canUpdateProjectStatus(row, statusLabel);
    }

    return false;
  }

  clickedRow(i) {

    //this.clickedMenu = this.clickedMenu === i ? -1 : i;
    document.getElementById("actions-" + i).style.zIndex = (++this.index).toString();
  }

  editField(event: any) {
    let index = this.rows.findIndex(x => x.id === event.id);
    this.rows[index][event.key] = event.value;

    this.rowsChanged.emit({ data: this.rows });
  }
}
