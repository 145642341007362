import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { REGEX_PATTERNS } from 'src/app/core/constants';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { IPager } from 'src/app/shared/pagination';
import { IQuery } from 'src/app/shared/query';
import { IUser } from 'src/app/shared/user';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-custom-client-list',
  templateUrl: './custom-client-list.component.html',
  styleUrls: ['./custom-client-list.component.css']
})
export class CustomClientListComponent implements OnInit, OnDestroy {
  currentUser: IUser;
  queryParam: IQuery;
  pagerObject: IPager;
  currentPageNumber: number = 1;
  subs: Subscription[] = [];
  canEdit = false;
  errorMsg: string = "";
  isError: boolean;
  filtered: boolean;
  filterForm: FormGroup;
  filterExists: boolean = true;
  isSubmitted = false;
  dunsIdSuggestions = [];
  filterColumn = '';
  isAsc = true;
  hasError = false;
  successMsg = '';
  clients = [];
  sortingObject;
  displayColumns = [
    {
      key: 'id',
      text: 'ID',
      colspan: 1
    },
    {
      key: 'title',
      text: 'Name',
      colspan: 3
    },
    {
      key: 'dunsId',
      text: 'Duns',
      colspan: 2
    },
    {
      key: 'isSrategicCLient',
      text: 'Is Custom Client',
      colspan: 1
    },
    {
      key: 'ModifiedOn',
      text: 'Last Updated Date',
      colspan: 1
    },
    {
      key: 'CreatedOn',
      text: 'Created Date',
      colspan: 1
    }
  ];

  constructor(private clientCommitmentsService: ClientCommitmentsService,  private projectService: ProjectsService) { }
 

  ngOnInit(): void {
    this.initFilterForm();
    this.initQueryParams();
    this.getList();
    this.getSuggestions();
  }

  private initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  private initFilterForm() {
    this.filterForm = new FormGroup({
      dunsId: new FormControl('')
    });
  }

  public filterList() {
    if (this.filterForm.valid) {
      this.initQueryParams();
      let filterValues = this.filterForm.value;
      if (filterValues['dunsId'])
        this.queryParam.clientTitleWithDuns = filterValues['dunsId']

      this.getList();
      this.filtered = true;
    }
  }

  public sort(column: string, type: string): void {
    if (this.filterColumn !== column) {
      this.filterColumn = column;
      this.isAsc = false;
    } else {
      this.isAsc = !this.isAsc;
    }
    this.queryParam.orderBy = this.isAsc ? this.filterColumn : `${this.filterColumn} desc`;

    this.queryParam.pageNumber = this.queryParam.pageNumber ?? 1;
    this.queryParam.pageSize = 10;
    this.refresh();
  }

  refresh(): any {
    this.getList(true);
  }

  private getList(isRefresh = false): any {
    const sub = this.clientCommitmentsService.getClients(this.queryParam, isRefresh).subscribe((res: any) => {
      console.log(res)
      this.clients = res.body.clients;
      if (res.headers.get('X-pagination')) {
        this.pagerObject = Object.assign({}, JSON.parse(res.headers.get('X-pagination')) as IPager);
      }
    }, (error) => {
      console.log(error);
    });
    this.subs.push(sub);
  }

  getSuggestions() {
    let suggestionSubscriber: any;
    let params = [];
    if (this.filterForm.value.dunsId && this.filterForm.value.dunsId.length >= 3) {
      params.push({ paramName: "titleWithDuns", value: this.filterForm.value.dunsId });
      suggestionSubscriber = this.projectService.getClientSuggestions("clients", params, 100);
    }
    else {
      suggestionSubscriber = this.projectService.getClientSuggestions("clients", null, 100);
    }

    suggestionSubscriber.subscribe(
      data => {
        this.dunsIdSuggestions = data;
      },
      error => { this.isError = true; this.errorMsg = error; }
    );

   // this.subs.push(suggestionSubscriber);
  }

  public clearFilterList() {
    this.filtered = false;
    this.initFilterForm();
    this.initQueryParams();
    this.refresh();
  }

  public updateStrategy(id: any, isStrategy = false): any {
    const sub = this.clientCommitmentsService.updateCustomClient(id, isStrategy).subscribe((res: any) => {
      this.refresh();
    }, (error) => {
      console.log(error);
    });
    this.subs.push(sub);
  }

  public addZIndex(index: any): any {

  }

  public changePage(page: number): void {
    this.queryParam.pageNumber = page;
    this.getList();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
